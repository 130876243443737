import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
  <Layout>
    <SEO
      title="Contact Us | Caledonian Tree Services"
      canonicalUrl={data.site.siteMetadata.siteUrl + "/contact-success/"}
      description=""
    />
    <Hero
      hero={data.hero.desktopImage.fluid}
      heroMobile={data.heroMobile.mobileImage.fluid}
      logo={data.logo.childImageSharp.fixed}
      arb={data.arb.childImageSharp.fixed}
      strapline="Scotland’s Premier Arboriculture & <br /> Commercial Tree Surgeon"
      straplineMobile="Scotland’s Premier Arboriculture<br /> & Commercial Tree Surgeon"/>
    <Navbar/>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <div className="">
              <h1 className="title is-size-5 is-size-6-mobile">Thanks for sending that through</h1>
              <p>We'll take a look over your query and one of our team will get in touch as soon as possible.</p><br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <h2>Caledonian Tree Services</h2>
            <p>South Craigmarloch<br />
              Port Glasgow Road<br />
              Kilmalcolm<br />
              PA13 4SG<br />
              Tel : 01505 872929 / 07801 277735<br />
              Fax : 01505 872929</p>
          </div>
          <div className="column">
          </div>
        </div>
      </div>
    </section>
  </Layout>
)}

export const query = graphql`
  query {
    hero: contentfulHeroImage(desktopImage: {title: {eq: "contact-hero"}}) {
      desktopImage{
        fluid{
          src
        }
      }
    }
    heroMobile: contentfulHeroImage(mobileImage: {title: {eq: "contact-hero-mobile"}}) {
      mobileImage {
        fluid {
          src
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mulch: file(relativePath: { eq: "index/mulch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sectional: file(relativePath: { eq: "index/sectional.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tractor: file(relativePath: { eq: "index/tractor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logs: file(relativePath: { eq: "index/logs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
      childImageSharp {
        fixed(width: 125, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
